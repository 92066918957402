import React, { Component } from 'react';
import logo from '../../shared/crafter_walking.svg';
import './page-not-found.css';

export default class PageNotFound extends Component {
    render() {
        return(
            <div id="container">
                <img className='logo' src={logo} alt="logo" />
                <div className='messaging'>
                    <span className='error'>404 Page Not Found</span>
                    <span className='message'>Uh oh, it looks like you lost your way...</span>
                    <a href='/'>Go Back Home</a>
                </div>
            </div>
        );
    }
}