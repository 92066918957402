const fetch = require('node-fetch');

export default class DataService {
    api_base = process.env.REACT_APP_API_URL;
    //api_base = 'http://blackiceorjames.stream:3001/api/';
    catalog_endpoint = 'acrafterstale/getCatalogListing';
    album_endpoint = 'acrafterstale/getAvailableAlbums';
    photo_endpoint = 'acrafterstale/getAvailablePhotos';
    purchased_photo_endpoint = 'acrafterstale/getPurchasedPhotos';
    photo_download_endpoint = 'acrafterstale/downloadPhoto';
    orders_endpoint = 'acrafterstale/order';
    public_token_endpoint = 'login/requestPublicToken';
    token_storage_key = 'crafters_&_token850';
    gadget_controller = 'gadgetcontroller';
    token = null;

    async postOrder(order) {
        let headers = await this.getHeaders('POST', order);

        return await fetch(`${this.api_base}${this.orders_endpoint}`, headers)
            .then(res => res.json());
    }

    async getOrder(orderID) {
        let headers = await this.getHeaders('GET');

        return await fetch(`${this.api_base}${this.orders_endpoint}/${orderID}`, headers)
            .then(res => res.json());
    }

    async getCatalogListing() {
        let headers = await this.getHeaders('GET');

        return await fetch(`${this.api_base}${this.catalog_endpoint}`, headers)
            .then(res => res.json());
    }

    async getAlbums() {
        let headers = await this.getHeaders('GET');

        return await fetch(`${this.api_base}${this.album_endpoint}`, headers)
            .then(res => res.json());
    }

    async getPhotosByAlbumId(albumId) {
        let headers = await this.getHeaders('GET');

        return await fetch(`${this.api_base}${this.photo_endpoint}/${albumId}`, headers)
            .then(res => res.json());
    }

    async getPurchasedPhotosByCode(photoCode) {
        let headers = await this.getHeaders('GET');

        return await fetch(`${this.api_base}${this.purchased_photo_endpoint}/${photoCode}`, headers)
            .then(res => res.json());
    }

    async getDownloadData(albumId, photoKey, photoCode) {
        let body = {
            albumId: albumId,
            photoKey: photoKey,
            photoCode: photoCode
        }

        let headers = await this.getHeaders('POST', body);

        return await fetch(`${this.api_base}${this.photo_download_endpoint}`, headers)
            .then(res => res.json());
    }

    async sendMobileMediaCommand(command, accessKey) {
        let body = { accessKey, command };

        let headers = await this.getHeaders('POST', body);

        return await fetch(`https://us-central1-global-api-d1b6f.cloudfunctions.net/api/${this.gadget_controller}`, headers)
            .then(res => res.json());
    }

    async getHeaders(requestMethod, requestBody) {
        let token = await this.getToken();

        let response = {
            headers: {
                'Content-Type': 'application/json'
            },
            method: requestMethod,
            body: JSON.stringify(requestBody)
        }

        if (token) {
            response.headers['Authorization'] = `Bearer ${token}`;
        }

        return response;
    }

    async getToken() {
        let token = sessionStorage.getItem(this.token_storage_key);

        if (token !== null) {
            return token;
        }

        // Get a public token from the API to keep locally
        let response = await fetch(`${this.api_base}${this.public_token_endpoint}`)
            .then(res => res.json());

        if (response.status === true && response.token) {
            sessionStorage.setItem(this.token_storage_key, response.token);

            return response.token;
        }

        return null;
    }
}