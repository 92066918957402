import { createSlice } from '@reduxjs/toolkit';
import Constants from 'common/constants.json';

let initState = getInitialCart();

export const slice = createSlice({
  name: 'checkout',
  initialState: {
    items: initState.items,
    totalCount: initState.totalCount,
    totalPrice: initState.totalPrice
  },
  reducers: {
    updateShoppingCart: (state, action) => {
      state.items[action.payload.key] = action.payload.value;
      state.totalCount = getTotalCount(state.items);
      state.totalPrice = getTotalPrice(state.items);
    },
    clearShoppingCart: (state) => {
      state.items = {};
      state.totalCount = 0;
      state.totalPrice = 0.00;
    }
  },
});

export const { updateShoppingCart, clearShoppingCart } = slice.actions;

export default slice.reducer;

function getTotalPrice(items) {
  let result = 0;

  for (const prop in items) {
    const element = items[prop];

    result += Number(element.price) * element.quantity;
  }

  return result;
}

function getTotalCount(items) {
  let result = 0;

  for (const prop in items) {
    const element = items[prop];

    result += element.quantity;
  }

  return result;
}

function getInitialCart() {
  let cart = localStorage.getItem(Constants.shoppingCartKey);

  if(cart) {
    cart = JSON.parse(cart);
  } else {
    return {
      items: {},
      totalCount: 0,
      totalPrice: 0.00
    }
  }

  return {
    items: cart.items,
    totalCount: cart.totalCount,
    totalPrice: cart.totalPrice
  };
}