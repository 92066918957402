import React, { Component } from 'react'
import { Image, Card } from 'semantic-ui-react';
import content from './content.json';
import './landing.component.css';

export default class Landing extends Component {

    render() {
        let craftCollection = [];

        content.crafts.forEach(craft => {
            craftCollection.push(
            <Card className='craft-intro-card' key={craft.title}>
                <Image src={craft.image_url} wrapped ui={false} />
                <Card.Content>
                    <Card.Header>{craft.title}</Card.Header>
                    <Card.Meta>
                        <span className='date'>Crafting since {craft.start_year}</span>
                    </Card.Meta>
                    <Card.Description>
                        <pre className='explanation'>{craft.explanation}</pre>
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <a href={craft.link}>
                        <span>View more ...</span>
                    </a>
                </Card.Content>
            </Card>);
        });

        return (
            <div id='container'>
                <h1 id='heading'>A Crafter's Tale . . .</h1>
                <p id='intro'>{content.introduction}</p>
                { craftCollection }
            </div>
        );
    }
}