import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearShoppingCart } from 'store/reducers/checkoutSlice';
import { Grid, Label } from 'semantic-ui-react';
import ProcessingButton from 'common/components/processing-button/processing-button.component';
import Constants from 'common/constants.json';
import './checkout-view.component.css';

export default function CheckoutView(props) {
    const dispatch = useDispatch();
    const items = useSelector((state) => state.checkout.items);
    const totalCount = useSelector((state) => state.checkout.totalCount);
    const totalPrice = useSelector((state) => state.checkout.totalPrice);
    const isFullView = props.viewMode === 'full';
    const clearCart = () => {
        // Update our local storage with the current cart to handle refresh persistence
        window.localStorage.removeItem(Constants.shoppingCartKey);

        dispatch(clearShoppingCart())
    }

    let itemList = [];

    for (const variation in items) {
        let item = items[variation];
        let price = Number(item.price);
        let basePriceHtml = item.quantity > 1 ? <span> (${price.toFixed(2)} each)</span> : <></>;

        itemList.push(
            <Grid.Row columns={2} key={variation} className="item-row">
                <Grid.Column width={6}>
                    <Grid.Row>
                        <Grid.Column>
                            <span className="text-muted">{item.category}</span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <span>{item.size}</span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column width={10}>
                    <Grid.Row>
                        <Grid.Column>
                            <strong>{item.name}</strong>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <span className="text-muted">
                                ${(price * item.quantity).toFixed(2)} {basePriceHtml}
                            </span>
                        </Grid.Column>
                        {
                            !isFullView &&
                            <Grid.Column>
                                <span>Quantity: {item.quantity}</span>
                            </Grid.Column>
                        }
                    </Grid.Row>
                </Grid.Column>
                {
                    isFullView &&
                    <Grid.Column>
                        <span>Quantity: {item.quantity}</span>
                    </Grid.Column>
                }
            </Grid.Row>
        );
    }

    return (
        <div className="container">
            <Grid>
                {/** Checkout Header */}
                <Grid.Row columns={2} className="order-summary">
                    <Grid.Column>
                        {
                            totalCount > 0 &&
                            <Label id="clear-action" circular color='blue' onClick={() => clearCart()}>
                                <i className="far fa-shopping-cart"></i>
                                <span> Empty Cart</span>
                            </Label>
                        }
                    </Grid.Column>
                    <Grid.Column className="order-total">
                        <span className="text-muted">Subtotal </span>
                        <span className="price">${totalPrice.toFixed(2)}</span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div className={"itemized-list " + props.viewMode}>
                            <Grid>
                                {itemList}
                            </Grid>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {
                            !props.hideAction && <ProcessingButton content={"View Cart (" + totalCount + " item(s))"}
                                color="blue" isloading={false}
                                 />
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}