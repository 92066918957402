import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Grid, Button } from 'semantic-ui-react';
import DataService from 'services/dataService';
import './mobile-media.component.css'

class MobileMedia extends Component {
    dataService = new DataService();
    state = {
        accessKey: '',
        message: '',
        recentDirection: '',
        isLoading: false
    };

    async componentDidMount() {
        const accessKey = this.props.match.params.code;

        this.state.accessKey = accessKey;
        this.state.message = !accessKey ? 'Looks like you don\'t have an access code set' : '';
    }

    async sendDirection(direction) {
        this.setState({ isLoading: true });
        
        const response = await this.dataService.sendMobileMediaCommand(direction, this.state.accessKey);
        console.log(response);

        this.setState({
            recentDirection: response.status ? direction : '?',
            message: response.message,
            isLoading: false
        })
    }

    render() {
        const { 
            accessKey,
            message,
            recentDirection,
            isLoading
         } = this.state;

        return (
            <div>
                <div id="controls">
                    <div className="panel">
                    <p className="header">Mobile Media Controller</p>
                        <div className="content">
                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column textAlign="center">
                                        <Button primary onClick={() => this.sendDirection('up')} loading={isLoading}>
                                            <i class="fa-duotone fa-sort-up"></i>
                                            <span>Up</span>
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column textAlign="center">
                                        <Button onClick={() => this.sendDirection('stop')} loading={isLoading}>
                                            <i class="fa-sharp fa-solid fa-sort"></i>
                                            <span>Stop</span>
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column textAlign="center">
                                        <Button primary onClick={() => this.sendDirection('down')} loading={isLoading}>
                                            <i class="fa-duotone fa-sort-down"></i>
                                            <span>Down</span>
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                    <Grid.Column textAlign="center">
                                        <p>
                                            <b>Access Key: </b>{accessKey}
                                        </p>
                                        <p>
                                            <strong>Message: </strong>{message || 'Try out the controls'}
                                        </p>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                        <div>
                            {recentDirection === 'up' && <i class="icon fa-regular fa-sort-up blueish"></i>}
                            {
                                (recentDirection === 'stop' || recentDirection === '') &&
                                <i className="icon fa-light fa-sort"></i>
                            }
                            {recentDirection === 'down' && <i class="icon fa-regular fa-sort-down blueish"></i>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MobileMedia);