import React, { useEffect, useState } from 'react';
import DataService from 'services/dataService';
import { Grid } from 'semantic-ui-react';
import './checkout-complete.component.css';

const dataService = new DataService();

export default function CheckoutComplete() {

    const [order, setOrderValue] = useState(null);
    const thankYouMsg = "Thank you for your order. An email confirmation was sent to you. If you have any questions about your order, please contact us at [insert_email]."
    let bannerStatus = '';
    let params = new URLSearchParams(window.location.search);
    let lineItems = [];

    const transactionId = params.get('transactionId');

    useEffect(() => {
        (async () => {
            let response = await dataService.getOrder(transactionId);

            if (response.order) {
                setOrderValue(response.order);
            } else {
                console.error('Failed to get order from server.');
            }
        })();
    }, []);

    if(!order) {
        return (<></>);
    }
    
    bannerStatus = order.state === "COMPLETED" ? 'success' : 'failure';
    order.lineItems.map((item) => {
        lineItems.push(
            <Grid.Row key={item.uid} columns={2}>
                <Grid.Column textAlign="left" width={12}>
                    <span>{`${item.quantity} x ${item.name} - ${item.variationName}`}</span>
                </Grid.Column>
                <Grid.Column textAlign="right" width={4}>
                    <span>{`$${(Number(item.totalMoney.amount)/100).toFixed(2)}`}</span>
                </Grid.Column>
            </Grid.Row>
        );
    });

    return (
        <div>
            <div id="notice-banner" className={bannerStatus}>
                <p>Order Placed Successfully</p>
            </div>
            <Grid>
                <Grid.Row>
                    <Grid.Column only="mobile">
                        <p className="thankyou-msg">{thankYouMsg}</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div id="order-details">
                <div>
                    <span className="text-muted">Order #</span>
                    <span className="float-right text-muted">{transactionId}</span>
                </div>
                <div className="divider"></div>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column width={6} only="tablet computer">
                            <p className="thankyou-msg">{thankYouMsg}</p>
                        </Grid.Column>
                        <Grid.Column mobile="16" tablet="10" computer="10">
                            <Grid>{lineItems}</Grid>
                            <div className="divider"></div>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column width={10} textAlign="left">
                                        <div>Total</div>
                                    </Grid.Column>
                                    <Grid.Column width={6} textAlign="right">
                                        <span>{`$${(Number(order.totalMoney.amount)/100).toFixed(2)}`}</span>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </div>);
}