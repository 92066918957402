import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Segment, Image, Button, Header, Modal } from 'semantic-ui-react';
import DataService from 'services/dataService';
import Contants from 'common/constants.json';
import PageLoader from 'common/components/page-loader/page-loader.component';
import AlbumList from './components/album-list.component';
import PhotoList from './components/photo-list.component';
import './photo-gallery.component.css';

class PhotoGallery extends Component {

    views = {
        photoList: "PHOTOS",
        albumList: "ALBUMS",
        purchasedPhotos: "PURCHASED_PHOTOS"
    }
    dataService = null;
    state = {
        albums: [],
        photos: [],
        purchasedPhotos: [],
        fullScreenImage: '',
        fullScreenOpen: false,
        view: this.views.albumList,
        isLoading: false
    }

    constructor() {
        super();
        this.dataService = new DataService();
    }

    async componentDidMount() {
        let photoCode = this.props.match.params.code;

        if (photoCode) {
            this.populatePurchasedPhotos(photoCode);
        } else {
            this.populateAlbumListing();
        }

        this.unlisten = this.props.history.listen(this.onHistoryChanged);
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        let purchases = this.state.purchasedPhotos || [];

        let purchasesHtml =
            <div className="photo-listing">
                <h4 className="text-muted">
                    NOTE: Purchased photos will be in their original size and will have the "© A Crafter's Tale" watermark removed when downloaded.
                </h4>
                {
                    purchases.map(item =>
                        <div className="item" key={item.photo}>
                            <Segment basic>
                                <Header>
                                    <img className="image" src={`${Contants.awsS3Prefix}${item.photo}`} alt=""></img>
                                </Header>
                                <Segment.Inline>
                                    <Button basic color="black" onClick={this.onDownload.bind(this, item)}>
                                        <i className="fa fa-cloud-download-alt"></i>
                                        <span>Download</span>
                                    </Button>
                                </Segment.Inline>
                            </Segment>
                        </div>
                    )
                }
            </div>

        return (
            <div>
                {
                    this.state.isLoading && <PageLoader content={this.getLoaderContent()}></PageLoader>
                }
                {
                    (!this.state.isLoading && this.state.view === this.views.albumList
                        && <AlbumList albumList={this.state.albums} onCatalogSelected={this.populatePhotoCatalog}></AlbumList>)
                    ||
                    (!this.state.isLoading && this.state.view === this.views.photoList
                        && <PhotoList photoList={this.state.photos} onFullscreen={this.fullScreenOpen}></PhotoList>)
                    ||
                    (!this.state.isLoading && this.state.view === this.views.purchasedPhotos && purchasesHtml)
                }
                <Modal
                    open={this.state.fullScreenOpen}
                    dimmer='blurring'
                >
                    <Modal.Content>
                        <Button basic floated="right" onClick={this.closeFullscreen.bind(this)}>
                            <i className="far fa-times"></i>
                        </Button>
                        <Image src={this.state.fullScreenImage}></Image>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }

    populateAlbumListing = async () => {
        let albumResult = null;

        this.setState({ isLoading: true });

        albumResult = await this.dataService.getAlbums();

        this.setState({
            albums: albumResult.status ? albumResult.response : [],
            view: this.views.albumList,
            isLoading: false
        });
    }

    populatePhotoCatalog = async (catalogId) => {
        this.setState({
            view: this.views.photoList,
            isLoading: true
        });

        let result = await this.dataService.getPhotosByAlbumId(catalogId);

        this.setState({
            photos: result.status ? result.response : [],
            isLoading: false
        });
    }

    populatePurchasedPhotos = async (photoCode) => {
        let purchasedPhotos = null;

        this.setState({
            view: this.views.purchasedPhotos,
            isLoading: true
        });

        purchasedPhotos = await this.dataService.getPurchasedPhotosByCode(photoCode);

        this.setState({
            purchasedPhotos: purchasedPhotos.status ? purchasedPhotos.response : [],
            isLoading: false
        });
    }

    onHistoryChanged = async (location, action) => {
        let photoCode = this.props.match.params.code;

        if (photoCode) {
            if (action === 'PUSH' && !location.pathname.endsWith(photoCode)) {
                this.populateAlbumListing();
            } else {
                this.populatePurchasedPhotos(photoCode);
            }
        } else {
            this.populateAlbumListing();
        }
    }

    getLoaderContent = () => {
        switch (this.state.view) {
            case this.views.albumList:
                return 'Loading Albums ...';
            case this.views.photoList:
                return 'Loading Photos ...';
            case this.views.purchasedPhotos:
                return 'Loading Download ...';
            default:
                return 'Loading';
        }
    }

    fullScreenOpen = (imageSrc) => {
        this.setState({
            fullScreenImage: imageSrc,
            fullScreenOpen: true
        });
    }

    closeFullscreen = () => {
        this.setState({ fullScreenOpen: false });
    }

    onDownload = async (item) => {
        this.setState({ isLoading: true });

        let photoCode = this.props.match.params.code;
        let result = await this.dataService.getDownloadData(item.albumId, item.key, photoCode);

        let link = document.createElement('a');
        link.href = result.response;

        link.download = item.key;
        link.click();

        this.setState({ isLoading: false });
    }
}


export default withRouter(PhotoGallery);