import React, { useState, useEffect, useRef } from 'react'
import { Menu, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentRoute } from 'store/reducers/navigationSlice';
import './navigation.component.css';
import icon from '../logo.svg';
import { RouteKeys } from 'common/references/keys';


export default function Navigation() {
    const dispatcher = useDispatch();
    const [activeRoute, setActiveRoute] = useState('/');
    const location = useLocation();
    const flyoverRef = useRef();

    // Set the initial navigation route on page load
    useEffect(() => {
        if (location.pathname.startsWith("/instructionals")) {
            setActiveRoute(RouteKeys.intructionals);
        } else if (location.pathname.startsWith("/shopping/checkout")) {
            setActiveRoute(RouteKeys.checkout);
        } else if (location.pathname.startsWith("/shopping")) {
            setActiveRoute(RouteKeys.shop);
        } else if (location.pathname.startsWith("/photo")) {
            setActiveRoute(RouteKeys.gallery);
        } else {
            setActiveRoute(RouteKeys.root);
        }
    }, [location.pathname]);

    const isActive = (routeKey) => {
        if (activeRoute === routeKey) {
            return true;
        }

        return false;
    }

    dispatcher(setCurrentRoute(activeRoute))

    return (
        <div>
            <Menu id="big-screen-menu">
                <Menu.Item as={Link} to="/" active={isActive(RouteKeys.root)} onClick={setActiveRoute.bind(null, RouteKeys.root)}>
                    <Image className='home-icon' src={icon} />
                </Menu.Item>
                <Menu.Item as={Link} to="/instructionals" active={isActive(RouteKeys.intructionals)} onClick={setActiveRoute.bind(null, RouteKeys.intructionals)}>
                    <i className="fad fa-scroll-old fa-2x"></i>
                    <span className='menu-item-text'>Instructionals</span>
                </Menu.Item>
                <Menu.Item as={Link} to="/shopping" active={isActive(RouteKeys.shop)} onClick={setActiveRoute.bind(null, RouteKeys.shop)}>
                    <i className="fad fa-store fa-2x"></i>
                    <span className='menu-item-text'>Craft Shop</span>
                </Menu.Item>
                <Menu.Item as={Link} to="/photo-gallery" active={isActive(RouteKeys.gallery)} onClick={setActiveRoute.bind(null, RouteKeys.gallery)}>
                    <i className="fad fa-camera-retro fa-2x"></i>
                    <span className='menu-item-text'>Photo Gallery</span>
                </Menu.Item>
            </Menu>
            <Menu id="small-screen-menu">
                <Menu.Item as={Link} to="/" active={isActive(RouteKeys.root)} onClick={setActiveRoute.bind(null, RouteKeys.root)}>
                    <Image className='home-icon' src={icon} />
                </Menu.Item>
                <Menu.Item as={Button} position="right" onClick={() => flyoverRef.current.classList.toggle('open')}>
                    <i className="fal fa-bars"></i>
                </Menu.Item>
            </Menu>
            <div ref={flyoverRef} id="flyover-nav">

            </div>
        </div>
    );
}