import React from 'react';
import { Grid, Input, Button, Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux'
import CheckoutView from 'common/components/checkout-view/checkout-view.component';
import DataService from 'services/dataService';
import './checkout.component.css';

function getItemTotal(items) {
    let total = 0.00;
    let item = null;

    for (let index in items) {
        item = items[index]
        total += (item.price * item.quantity);
    }

    return total;
}

async function checkout(items) {
    let dataService = new DataService();
    let payload = {
        items: items,
        discountCode: null
    }

    let checkout = await dataService.postOrder(payload);
    window.location.href = checkout.checkoutPageUrl;
}

export default function Checkout() {
    let shoppingItems = useSelector((state) => state.checkout.items);
    let itemTotal = getItemTotal(shoppingItems);
    let shipping = 7.50;

    return (
        <div id='checkout-page'>
            <Grid padded columns={2} stackable>
                <Grid.Row>
                    {/** Detailed Items Column */}
                    <Grid.Column id="order-detail-panel" mobile={16} tablet={16} computer={11}>
                        {/** Action Panel */}
                        <CheckoutView viewMode='full' hideAction={true}></CheckoutView>
                    </Grid.Column>
                    {/** Order Summary Column */}
                    <Grid.Column id="order-summary-panel" mobile={16} tablet={16} computer={4} floated="right">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <span className="spaced-header-text white">order summary</span>
                                    <hr></hr>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <p className="white">Subtotal</p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p className="white">${itemTotal.toFixed(2)}</p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <p className="white">Shipping</p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p floated="right" className="white">${shipping.toFixed(2)}</p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <hr></hr>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <strong className="white">Grand Total</strong>
                                </Grid.Column>
                                <Grid.Column>
                                    <strong className="white">${(itemTotal + shipping).toFixed(2)}</strong>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                <Input action='Search' placeholder='Search...' />
                                    <Input iconPosition='left' placeholder='Have a promo code?'>
                                        <Icon name='tag' />
                                        <input />
                                    </Input>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Button id="purchase-btn" color="black" onClick={() => checkout(shoppingItems)}>
                            <i className="fal fa-credit-card"></i> Checkout
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}