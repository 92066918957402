import React, { Component } from 'react';
import { Segment, Header, Button } from 'semantic-ui-react';
import './listing.component.css';

export default class AlbumList extends Component {

    onImageLoaded(event) {
        let element = event.target;

        element.classList.remove('loading');
    }

    render() {
        return (
            <div className="album-listing">
                {
                    this.props.albumList.map(album =>
                        <div className="item" key={album.id}>
                            <Segment basic>
                                <Header>
                                    <p className="title">{album.displayName}</p>
                                    <img className="image loading" src={album.coverUrl} alt="" onLoad={this.onImageLoaded.bind(this)}></img>
                                </Header>
                                <Segment.Inline>
                                    <Button onClick={this.props.onCatalogSelected.bind(this, album.id)}>
                                        <i className="far fa-lock-open-alt"></i>
                                        <span>Open</span>
                                    </Button>
                                </Segment.Inline>
                            </Segment>
                        </div>
                    )
                }
            </div>
        );
    }
}