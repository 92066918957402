import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
//import './page-loader.component.css';

export default class ProcessingButton extends Component {
    render() {
        return (
            <Button style={{width: "100%"}} color={this.props.color} loading={this.props.isloading}
                onClick={() => this.props.click && this.props.click()}>
                <span>{this.props.content || 'Process'}</span>
            </Button>
        );
    }
}