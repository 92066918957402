import React, { Component } from 'react';
import { Segment, Dimmer, Loader, Image } from 'semantic-ui-react';
import paragraphImg from  'shared/short-paragraph.png';
import './page-loader.component.css';

export default class PageLoader extends Component {

    render() {
        return (
            <Segment>
                <Dimmer active inverted>
                    <Loader size='big' content={this.props.content || "Loading..."} inverted />
                </Dimmer>

                <Image className='background' src={paragraphImg} />
                <Image className='background' src={paragraphImg} />
            </Segment>
        );
    }
}