import React, { useState } from 'react';
import { Dropdown, Popup, Button, Grid } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { updateShoppingCart } from 'store/reducers/checkoutSlice';
import './item.component.css';

export default function Item(props) {
    const dispatcher = useDispatch();
    const [quantity, setQuantity] = useState(1);
    const [variationId, setVariationId] = useState(null);
    const item = props.item;
    const increaseCount = () => {
        setQuantity(quantity + 1);
    };

    const decreaseCount = () => {
        let reduced = quantity - 1;

        if (reduced <= 0) {
            reduced = 1;
        }

        setQuantity(reduced);
    };

    const getTotalCost = () => {
        let priceData = 0;

        if (item) {
            let variation = item.variations.find(vari => vari.id === variationId);

            if (variation) {
                priceData = variation.itemVariationData.priceMoney;

                if (!priceData) {
                    return 0.00.toFixed(2);
                } else {
                    return (priceData.amount * quantity / 100).toFixed(2);
                }
            }
        }

        return '';
    };

    const getDropdownContent = () => {
        let result = [];

        item.variations.forEach(item => {
            result.push({
                key: item.itemVariationData.name,
                text: item.itemVariationData.name,
                value: item.id,
                icon: "fad fa-candle-holder fa-2x"
            });
        });

        return result;
    };

    const getBasePrice = () => {
        let variation = item.variations.find(vari => vari.id === variationId);
        let priceData = null;

        if (variation) {
            priceData = variation.itemVariationData.priceMoney;
        }

        if (!priceData) {
            return 0.00.toFixed(2);
        } else {
            return (priceData.amount / 100).toFixed(2);
        }
    }

    const getVariationName = () => {
        let variation = item.variations.find(vari => vari.id === variationId);

        if (!variation) {
            return '';
        }

        return variation.itemVariationData.name;
    }

    let variationList = getDropdownContent();
    let price = getTotalCost();
    let checkoutItem = {
        key: variationId,
        value: {
            name: item.name,
            category: props.category,
            size: getVariationName(),
            price: getBasePrice(),
            quantity: quantity
        }
    }

    return (
        <div className="inventory_card">
            <div className="top_section">
                <div className="pricing">{price ? `$${price}` : ''}</div>
                <Popup content={item.description}
                    trigger={
                        <Button className="description">
                            <i className="far fa-info-square"></i>
                        </Button>} />

                <div className="category_picture">
                    <img src="https://crafterstalepublic.s3.amazonaws.com/main/candle_group_focus.jpg" alt="" />
                </div>
                <div className="name">{item.name}</div>
                <div className="tag">{props.category}</div>
                <div className="variation_dropdown">
                    <Dropdown placeholder='Select a size' selection options={variationList}
                        onChange={(event, obj) => setVariationId(obj.value)} />
                </div>
            </div>
            <div className="actions">
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Button.Group size="small" compact>
                                <Button className="" onClick={decreaseCount}>
                                    <i className="far fa-minus"></i>
                                </Button>
                                <Button disabled>
                                    <big className="">{quantity}</big>
                                </Button>
                                <Button className="" onClick={increaseCount}>
                                    <i className="far fa-plus"></i>
                                </Button>
                            </Button.Group>
                        </Grid.Column>
                        <Grid.Column>
                            <Button content='Add to Cart' icon='tags' size="tiny" basic color="black" labelPosition='left'
                                onClick={() => dispatcher(updateShoppingCart(checkoutItem))} disabled={!variationId} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </div>
    );
}