import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import './instructionals.component.css';

export default class Instructionals extends Component {
    render() {
        return (
            <div>
                <div id="categories">
                    <div className="category-panel">
                        <div>
                            <span className="category-text">Soy Candles</span>
                        </div>
                        <div>
                            <i className="category-icon fad fa-candle-holder orange"></i>
                        </div>
                    </div>
                    <div className="category-panel">
                        <div>
                            <span className="category-text">Soap</span>
                        </div>
                        <div>
                            <i className="category-icon fal fa-hands-wash silver"></i>
                        </div>
                    </div>
                    <div className="category-panel">
                        <div>
                            <span className="category-text">Homebrews</span>
                        </div>
                        <div>
                            <i className="category-icon fad fa-glass-cheers brownish"></i>
                        </div>
                    </div>
                    <div className="category-panel">
                        <div>
                            <span className="category-text">Food</span>
                        </div>
                        <div>
                            <i className="category-icon fad fa-utensils-alt"></i>
                        </div>
                    </div>
                    <div className="category-panel">
                        <div>
                            <span className="category-text">Horticulture</span>
                        </div>
                        <div>
                            <i className="category-icon fal fa-hand-holding-seedling green"></i>
                        </div>
                    </div>
                    <div className="category-panel">
                        <div>
                            <span className="category-text">Woodworking</span>
                        </div>
                        <div>
                            <i className="category-icon fad fa-axe brown"></i>
                        </div>
                    </div>
                </div>
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column textAlign="center">
                            <h2 style={{marginTop: "60px"}}> Content coming soon ... </h2>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}