import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'navigation',
  initialState: {
    currentRoute: '/'
  },
  reducers: {
    setCurrentRoute: (state, action) => {
      state.currentRoute = action.payload;
    }
  },
});

export const { setCurrentRoute } = slice.actions;

export default slice.reducer;