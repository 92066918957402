import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Grid } from 'semantic-ui-react';
import DataService from 'services/dataService';
import Item from './item.component';
import PageLoader from 'common/components/page-loader/page-loader.component';
import Constants from 'common/constants.json';
import './shopping.component.css';

export default function Shopping() {
    const items = useSelector((state) => state.checkout.items);
    const totalCount = useSelector((state) => state.checkout.totalCount);
    const totalPrice = useSelector((state) => state.checkout.totalPrice);

    const getItemsByCategory = (categoryId, categoryName, itemList) => {
        let items = [];

        if (itemList) {
            itemList.map((item) => {
                if (item.itemData.categoryId === categoryId && !item.isDeleted) {
                    items.push(
                        <Item key={item.id} item={item.itemData} category={categoryName}></Item>
                    )
                }

                return items;
            });
        }

        return (
            <Tab.Pane>
                {items}
            </Tab.Pane>
        );
    }

    const [cartCatalog, setCartCatalog] = useState({
        catalog: null,
        selectedCategoryId: null,
        categories: null,
        items: null,
        inventoryTabs: [{
            menuItem: 'Loading...',
            render: () => <Tab.Pane loading></Tab.Pane>
        }]
    })

    // Update our local storage with the current cart to handle refresh persistence
    window.localStorage.setItem(Constants.shoppingCartKey, JSON.stringify({ items, totalCount, totalPrice }));

    let dataService = new DataService();

    useEffect(() => {
        (async () => {
            let tabs = [];
            let catalog = await dataService.getCatalogListing();

            if (catalog) {
                catalog.categories.forEach((category) => {
                    // Generate each item list that will be fitted inside of the tab pane by category
                    let tabPaneRender = getItemsByCategory(category.id, category.categoryData.name, catalog.items);

                    tabs.push({
                        menuItem: category.categoryData.name,
                        render: () => tabPaneRender
                    });
                });

                setCartCatalog({
                    ...cartCatalog,
                    catalog: catalog,
                    categories: catalog.categories,
                    items: catalog.items,
                    inventoryTabs: tabs
                });
            } else {
                console.error('Failed to get inventory from server.');
            }
        })();
    }, []);

    return (
        <div>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column textAlign="center">
                        <p>
                            <i className="far fa-construction fa-3x"></i>
                            This site is currently under construction
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {
                !cartCatalog.catalog && <PageLoader content='Loading Catalog...'></PageLoader>
            }
            {cartCatalog.catalog && <Tab id='contentTab' panes={cartCatalog.inventoryTabs} />}
        </div>
    );
}