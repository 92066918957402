import React, { Component } from 'react';
import { Segment, Header, Button } from 'semantic-ui-react';
import Contants from 'common/constants.json';
import './listing.component.css';

export default class PhotoList extends Component {

    onImageLoaded(event) {
        let element = event.target;

        element.classList.remove('loading');
    }

    render() {
        return (
            <div className="photo-listing">
                {
                    this.props.photoList.map(item =>
                        <div className="item" key={item.id}>
                            <Segment basic>
                                <Header>
                                    <img className="image loading" src={`${Contants.awsS3Prefix}${item.photo}`} alt="" onLoad={this.onImageLoaded.bind(this)}></img>
                                </Header>
                                <Segment.Inline>
                                    <Button black basic onClick={this.props.onFullscreen.bind(this, `${Contants.awsS3Prefix}${item.photo}`)}>Enlarge</Button>
                                    {/*<Button onClick={this.onCatalogSelected.bind(this, item.id)} disabled>Add to Cart</Button>*/}
                                </Segment.Inline>
                            </Segment>
                        </div>
                    )
                }
            </div>
        );
    }
}