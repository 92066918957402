import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { Button, Popup, Label } from 'semantic-ui-react';
import { RouteKeys } from 'common/references/keys';
import CheckoutView from 'common/components/checkout-view/checkout-view.component';
import Navigation from './components/navigation.component';
import PageNotFound from './pages/404/page-not-found';
import { 
  Landing, Instructionals, PhotoGallery,
  Shopping, Checkout, CheckoutComplete,
  MobileMedia
} from 'pages/application';
import logo from './logo.svg';
import './App.css';

export default function App() {
  const totalItemsInCart = useSelector((state) => state.checkout.totalCount);
  const currentRoute = useSelector((state) => state.navigation.currentRoute);
  const [displaySplash, setDisplaySplash] = useState(true);
  const isCheckoutRoute = currentRoute === RouteKeys.checkout;
  const splashScreen =
    <div id='splash-screen' className={displaySplash ? '' : 'hidden'}>
      <img src={logo} alt="splash" />
    </div>;

  const checkoutBtn =
    <Button id="floating-checkout-btn">
      <i className="fas fa-shopping-cart"></i>
      <span>Cart</span>
      <Label circular color='blue'>{totalItemsInCart}</Label>
    </Button>

  window.addEventListener('load', (event) => {
    setDisplaySplash(false);
  });

  return (
    <Router>
      <div id="Application" className="App">
        {splashScreen}
        <div>
          <Navigation></Navigation>
          {
            // Hide the checkout nav button while we are on the checkout page
            !isCheckoutRoute && 
            <Popup content={<CheckoutView action={checkout}></CheckoutView>} trigger={checkoutBtn} on='click' wide />
          }
        </div>
        <div className="content">
          <Switch>
            <Route exact path="/">
              <Landing></Landing>
            </Route>
            <Route path="/instructionals">
              <Instructionals></Instructionals>
            </Route>
            <Route path="/instructionals/:section">
              <Instructionals></Instructionals>
            </Route>
            <Route exact path="/shopping">
              <Shopping></Shopping>
            </Route>
            <Route path="/shopping/checkout">
              <Checkout></Checkout>
            </Route>
            <Route path="/shopping/checkout-complete">
              <CheckoutComplete></CheckoutComplete>
            </Route>
            <Route exact path="/photo-gallery">
              <PhotoGallery></PhotoGallery>
            </Route>
            <Route path="/photo-gallery/:code">
              <PhotoGallery></PhotoGallery>
            </Route>
            <Route exact path="/photos">
              <PhotoGallery></PhotoGallery>
            </Route>
            <Route path="/photos/:code">
              <PhotoGallery></PhotoGallery>
            </Route>
            <Route path="/mobile-media/:code">
              <MobileMedia></MobileMedia>
            </Route>
            <Route path="/">
              <PageNotFound></PageNotFound>
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

function checkout() {
  window.location.href = '/shopping/checkout'
}