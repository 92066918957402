import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './store/reducers';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import DataService from 'services/dataService';
import '@fortawesome/fontawesome-pro/js/all';

const semenatic_style = document.createElement("link");
semenatic_style.rel = "stylesheet";
semenatic_style.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";

const font_style = document.createElement("link");
font_style.rel = "stylesheet";
font_style.href = "https://fonts.googleapis.com/css?family=Lobster&amp;display=swap";

document.head.appendChild(semenatic_style);
document.head.appendChild(font_style);
const dataService = new DataService();

// Fetch our token for the session if one does not exist
dataService.getToken();

const store = createStore(rootReducer);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
